<template>
	<div>
		<h1>Permisos</h1>
	</div>
</template>

<script>
import {
	BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
	BPagination, BTooltip, BIconPenFill, BOverlay, BIcon,
	BIconArrowUp, BIconArrowDown, BIconAlarm,
	BInputGroup, BInputGroupPrepend, BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
	components: {
		BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
		BPagination, BTooltip, BIconPenFill, BOverlay, BIcon,
		BIconArrowUp, BIconArrowDown, BIconAlarm,
		BInputGroup, BInputGroupPrepend, BInputGroupAppend,
		vSelect,
	},
};
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
</style>
<style lang="scss">
	@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
	.content-header .breadcrumb {
		display: flex !important;
	}
</style>